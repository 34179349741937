.media-gallery-modal {
  img.image-gallery-thumbnail-image {
    width: 100%;
    height: 50px;
    object-fit: contain;
  }

  img.image-gallery-image, .video-wrapper {
    max-height: unset;
    height: calc(60vh - 80px);
  }

  .video-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      max-width: 600px;
      max-height: 100%;
    }
  }
}

.media-gallery-modal {
  .image-gallery-content.fullscreen {
    img.image-gallery-image, .video-wrapper {
      max-height: unset;
      height: calc(100vh - 80px);

      video {
        max-width: 1600px;
      }
    }
  }
}