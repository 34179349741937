.component-radio-button {
  display: block;

  input[type='radio'] {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }

  input:checked + label {
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    color: #fff;
    border: 1px solid transparent;
  }

  label {
    display: block;
    padding: 16px;
    background: #fff;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 3px;
    width: 100%;
  }

  &:not(:first-child) {
    margin-top: 8px;
  }
}