.component-profile-photo-picker {
  .gallery-preview {
    flex-wrap: wrap;
    max-width: 100%;

    .image-container {
      position: relative;
      flex: 1 1 7rem;
      padding: 2px;
      width: 8rem;
      border: 3px solid #fff;

      img {
        display: block;
        border-radius: 4px;
        width: 100%;
        height: 6rem;
        object-fit: cover;
        object-position: 50% 20%;
        cursor: pointer;
      }

      &.--selected {
        border: 3px solid #1890ff;
        border-radius: 4px;
      }
    }
  }
}