.skeleton-hirer-view-rating-card {
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }

  .ant-typography {
    display: block;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .text-success {
    color: #16B55E;
  }

  .text-danger {
    color: #F03847;
  }

  .text-black {
    color: black;
  }

  .font-bold {
    font-weight: 600;
  }

  .p-0 {
    padding: 0;
  }

  .header {
    padding: 16px;
    align-items: center;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);

    .title {
      font-size: 1.4rem;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .talent-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .left-column {
      flex: 1;

      .photo {
        flex: 1 1 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .right-column {
      flex: 2;
      align-items: center;

      .summary {
        padding: 8px 20px;

        .role-title {
          font-size: medium;
          color: black;
        }

        .shoot-dates {
          font-size: small;
          color: #444444;
        }

        .hire-again {
          font-size: medium;
        }
      }
    }
  }

  .rating-item-container {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .rating-item {
      justify-content: space-between;
      align-items: baseline;

      .item-label {
        font-weight: 600;
      }

      .rating-stars {
        color: #574DD3;
      }
    }

    .written-review {
      color: black;
    }

    .link-action {
      font-weight: 600;
      color: #574DD3;
    }
  }

  .other-details {
    padding: 1rem;
  }
}