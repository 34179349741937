.talent-rating-comment-modal {
  margin: 24px 0 0;
  padding: 0;
  overflow-y: auto;
  max-height: 80vh;

  &:not(:last-child) {
    border-bottom: 1px solid #A4A4A4;
  }

  .comment-replies {
    border-left: 4px solid #EEEEEE;
  }
}