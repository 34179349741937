.image-cropper-modal {
  .cropper-container {
    position: relative !important;
    width: 100%;
    height: 40vh;
  }

  .slider-control {
    display: flex;
    padding: 0.5rem 0;

    .ant-btn {
      margin: 0 0.5rem;
    }

    .ant-slider.ant-slider-horizontal {
      flex-grow: 1;
    }
  }
}