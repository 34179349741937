.component-button {
  color: #574dd3;
  font-weight: 600;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #574dd3;
  border-radius: 3px;
  padding: 8px 16px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    color: #A4A4A4;
    border-color: #A4A4A4;
  }

  &:visited {
    color: #574dd3;
  }

  &.--primary {
    color: #fff;
    background: linear-gradient(135deg, #647dee 0%, #7f53ac 100%);
    font-weight: 400;

    &:visited {
      color: #fff;
    }
  }

  &.--block {
    width: 100%;
  }

  &.--rounded {
    border-radius: 25px;
  }

  &.--solid {
    background-color: #574dd3;
    color: white;

    &:disabled {
      background-color: #A4A4A4;
      border: 0;
    }
  }

  &.--ghost {
    border: 0;
  }
}